import React, { useCallback, useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { formatMoneyCurrency } from "../../../../utils";
import { useTooltipAttributes } from "../../../../hooks";
import { Csku, Typography, colors } from "@commonsku/styles";

type TColumn = typeof BASE_COLUMN_TYPES.Money;
const MoneyCell = ({
  column,
  row,
  className,
  onClick,
  style = {},
  textStyle = {},
}: CellProps<TColumn>) => {
  const {
    accessorKey,
    dataTransform,
    moneyOptions: { decimalPlaces = 0, colorful = false },
  } = column;

  const { amount, currency_id, tooltipText } = useMemo<ColumnDataMap<TColumn>>(() => {
    if (!dataTransform) throw new Error("MoneyCell requires dataTransform");

    const { amount, currency_id, tooltipText } = dataTransform(row.original[accessorKey]);

    return {
      amount,
      currency_id,
      tooltipText,
    };
  }, [accessorKey, dataTransform, row.original]);

  let color = style.color || "inherit";
  if (colorful) {
    if (parseFloat(amount) > 0) {
      color = colors.green[60];
    }
    if (parseFloat(amount) < 0) {
      color = colors.errors[60];
    }
  }

  const moneyText = useCallback(
    (decimal = decimalPlaces) =>
       amount === null ? '—' : formatMoneyCurrency(amount, currency_id, decimal),
    [amount, currency_id, decimalPlaces],
  );

  const tooltipAttributes = useTooltipAttributes(
    tooltipText ?? (amount !== null ? moneyText(2) : false),
    REPORT_TOOLTIP_TARGET_ID
  );

  return (
    <Csku className={className} onClick={onClick}>
      <div style={style} {...tooltipAttributes}>
        <Typography.Span
          style={{
            display: "block",
            alignContent: "center",
            color: color,
            ...textStyle,
          }}
        >
          {moneyText()}
        </Typography.Span>
      </div>
    </Csku>
  );
};

export default MoneyCell;
